import { useEffect, useState } from "react"

export const useIE = () => {
  const [isIE, setIsIE] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      setIsIE(isIE)
    }
  }, isIE)

  return isIE
}
