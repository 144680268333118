import React from "react"
import * as styles from "./newsCard.module.scss"
import ProgressiveImage from "react-progressive-image-loading"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { useIE } from "../../../utils/use-ie"

const NewsCard = ({
  snippet,
  date,
  image,
  imageMin,
  url,
  tag,
  additionalStyles,
}) => {
  const isIE = useIE()
  return (
    <div className={isIE ? styles.newsContainerIE : styles.newsContainer}>
      <ProgressiveImage
        preview={imageMin}
        src={image}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => (
          <div
            className={styles.newsImageContainer}
            style={Object.assign(style, {
              backgroundImage: `url(${src})`,
              ...additionalStyles,
            })}
          />
        )}
      />

      <div className={styles.newsInfo}>
        <h4>{tag}</h4>
        <h3>{date}</h3>
        <p>{snippet}</p>
        <OutboundLink href={url} target="_blank">
          Read More
        </OutboundLink>
      </div>
    </div>
  )
}

export default NewsCard
