import React from "react"
import NewsCard from "./newsCard"
import * as styles from "./newsContainer.module.scss"

export const NewsContainer = ({ first, second }) => (
  <div className={styles.newsContainer}>
    <NewsCard {...first} />
    {second ? <NewsCard {...second} /> : null}
  </div>
)
